import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import axios from 'axios';


library.add(fas, far, fab)
dom.watch();

// createApp(App).use(router).mount('#app')


// Define a URL para trabalho de acordo com a situação do sistema
if(location.hostname == 'gedi.amee.com.br'){
  axios.defaults.baseURL = 'https://gedi.amee.com.br/api/public/api';
}
else if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
  axios.defaults.baseURL = 'http://127.0.0.1:8000/api'
}
// axios.defaults.baseURL = 'https://gedi.amee.com.br/api/public/api'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api'

axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true'
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type, X-Request-With, X-Requested-By'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(router)
    .mount('#app')


window.$ = window.jQuery = require("jquery");

