<template>
  <link href="https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap" rel="stylesheet">
  <!-- <script src="https://kit.fontawesome.com/86625d9d3e.js" crossorigin="anonymous"></script> -->
  <div id="container">

    
            <div id="login-area">
                <div id="login-block">
                    <router-link to="/login">
                      <h1 class="title">Gedi</h1>
                    </router-link>
                    <form id="login" action="post" @submit.stop.prevent="submitLogin">
                        <div class="input-area">
                            <input type="email" name="usuario" id="login-gedi" placeholder="E-mail" v-model="email" required>
                            <input type="password" name="senha" id="password-gedi" placeholder="Senha"  v-model="password" required>
                            <input type="submit" value="Entrar" id="form-login" class="btn-login">
                        </div>
                    </form>
                    <p hidden id="error-message">Login Inválido</p>
                </div>
            </div>
            <div>
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g class="parallax">
        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(0,0,0,0.7" />
        <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(0,0,0,0.5)" />
        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(0,0,0,0.3)" />
        <use xlink:href="#gentle-wave" x="48" y="7" fill="rgba(0,0,0,0.4)" />
        </g>
        </svg>
    </div>
        </div>
</template>



<script>

import axios from 'axios';
import Cookie from 'js-cookie'


export default {
    el: '#app',
  data() {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    submitLogin(){

        const payload ={
            email: this.email,
            password: this.password
        }

         axios.post(`${axios.defaults.baseURL}/login`, payload)
        .then((res) => {
            // console.log(res)
            Cookie.set('_myapp_token', res.data.token)
            this.$router.push({name: 'dashboard'})
        })
        .catch((error) => {
            let errormessage = document.getElementById('error-message');
            errormessage.style.display = 'block';
        });
    }

  },


}


</script>





<style>

/* Configurações Gerais e Seletores */

html, body{
    margin: 0;
    padding: 0;
    height:100%;
	width:100%;
    font-size: 1.2rem;
    box-sizing: border-box;
    /* background: linear-gradient(45deg, #5e5e5e, #747474, #b8b8b8, #fafafa); */
    /* background-size: 400% 400%; */
    background: #F7F7FF;
    font-family: Arial, Helvetica, sans-serif;
    /* animation: gradient 10s ease infinite; */
}

 h1{
     margin: 0;
 }

 a{
     text-decoration: none;
 }

 /* Variavel */

:root {
   --color-background: #F7F7FF;
   --color-black: #000;
   --color-white: #fff;
   --color-hover: #3b3b3b;
   --color-background-dash: #212121;
   --color-dark: #252525;
 }


 /* Login */
#login-area{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.title{
    color: var(--color-black);
    margin-bottom: 20px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 5.7rem;
    border-radius: 10px;
}

.waves {
  position:absolute;
  bottom: 0;
  width: 100%;
  height:35vh;
  /* margin-bottom:-7px; */
  min-height:100px;
  max-height:150px;
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}

#login-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid var(--color-black);
    padding: 30px 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px var(--color-black);
    background: var(--color-white);
}

.input-area{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-area > input[type=email], .input-area > input[type=password]{
    margin: 5px;
    width: 250px;
    height: 35px;
    border: none;
    outline: none;
    border-bottom: 1.9px solid var(--color-black);
    text-align: center;
    font-size: 0.9rem;
}

.input-area > input[type=email]:focus, .input-area > input[type=password]:focus{
    border-bottom: 2px solid var(--color-black);
}

#forgot-pass-gedi{
    border: 2px solid var(--color-black);
    outline: none;
}


.btn-login{
    margin: 30px 0;
    width: 85%;
    height: 50px;
    border-radius: 5px;
    background: var(--color-black);
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    transition: 0.6s;
}

#login .input-area input:focus::placeholder{
    /* transform: translatex(-155px); */
    transform: translateY(-155px);
    transition: 3s;
    /* background-color: rgb(21, 218, 53); */
    border-radius: 40px;
    /* box-shadow: 0px 0px 10px rgb(126, 238, 132); */
    height: 100%;
}

.btn-login:hover{
    background: #202020;
    width: 260px;
    height: 60px;
    cursor: pointer;
}

.forgot-password{
    font-size: 0.9rem;
    color: rgb(109, 108, 108);
}

.forgot-password > a{
    font-weight: 900;
    color: var(--color-black);
}

.forgot-password > a:hover{
    text-decoration: underline;
}

#error-message{
    font-size: 1.1rem;
    color: rgb(230, 39, 39);
    margin: 0;
}


@keyframes gradient {
	0% {
		background-position: 0% 45%;
	}
	50% {
		background-position: 100% 45%;
	}
	100% {
		background-position: 0% 45%;
	}
}


</style>
