import Cookie from 'js-cookie';
import axios from 'axios';

export default{


    // Realiza a verificação da sessão do usuário
    auth(to, from, next){
        const token = Cookie.get('_myapp_token');

        if(!token){
            next('/login');
        }else{
        }
        next();

    },

    // Realiza a verificação da sessão do usuário e bloqueia algumas rotas
    validateToken(to, from, next){
        const token = Cookie.get('_myapp_token');

        let url_atual = window.location.href;
        console.log(url_atual)
        if(!token){
            next('/login');
        }else{
            next('/dashboard')
        }

    },
    

    /*Função que verifica a sessão do usuário, em caso de sessão expirada ele joga o usuário na página de login*/
    
    beforeCreate: function(){          
        setInterval(function(){
            const userToken = Cookie.get('_myapp_token');
        
            const payload ={
                token: userToken
            }

            axios
            .post(`${axios.defaults.baseURL}/validaToken`, payload)
            .then((res) => {
            })
            .catch((error) => {
                window.location.href = "http://localhost:8080/login";
            });

        }.bind(this), 110000);
    }, 

        
}


