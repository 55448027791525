import { createRouter, createWebHistory, onBeforeRouteUpdate } from 'vue-router'
import LoginView from '../views/Login_Page.vue'
import Autocontroller from '../services/middleware'

const routes = [

  {
    path: '/',
    name: '',
    component: LoginView,
    beforeEnter: Autocontroller.auth,
    beforeEnter: Autocontroller.validateToken,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login_Page.vue'),
    // beforeEnter: Autocontroller.auth,
  },
  {
    path: '/password-forgot',
    name: 'password-forgot',
    component: () => import('../views/Forgot_password.vue'),
    beforeEnter: Autocontroller.validateToken,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/dashboard.vue'),
    beforeEnter: Autocontroller.auth,
    beforeEnter: Autocontroller.beforeCreate,
  },
  {
    path: '/po',
    name: 'po',
    component: () => import('../components/po.vue'),
    beforeEnter: Autocontroller.auth,
    beforeEnter: Autocontroller.validateToken,
  },
  {
    path: '/consultas',
    name: 'consultas',
    component: () => import('../components/consultas/consultas.vue'),
    beforeEnter: Autocontroller.auth,
    beforeEnter: Autocontroller.validateToken,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
